import logo from './logo.svg';
import './App.css';
import fb from "./fb.png"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={fb} className="App-logo" alt="logo" />
        <p>

          
          <code>
            Site temporarily down for scheduled improvements. <br /> Exciting updates on the way
          </code>
        </p>

      </header>
    </div>
  );
}

export default App;
